import React from 'react';
import styled from 'styled-components';
import { IoMdMail } from 'react-icons/io';
import { FaTwitter, FaGithub } from 'react-icons/fa';

import variables from '../styles/variables';
import Layout from '../components/layout';
import { PageTitle } from '../components/titles';

const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme.colorPrimaryDark}aa;
  box-shadow: 0 0 20px ${props => props.theme.colorPrimaryDark}aa;
  text-shadow: 2px 2px 0 ${props => props.theme.colorTextDark};
  border-radius: 10px;

  @media only screen and (min-width: calc(${variables.screenWidth} + 1px)) {
    max-width: 400px;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledA = styled.a`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 6rem;
  transition: color 0.2s;
  color: ${props => props.theme.colorButtonBackground};

  @media only screen and (min-width: calc(${variables.screenWidth} + 1px)) {
    &:hover {
      color: ${props => props.theme.colorSecondaryLight};
    }
  }
`;

const ContactText = styled.p`
  font-size: 2rem;
  margin-bottom: 3rem;
`;

const ContactPage = () => {
  return (
    <>
      <PageTitle>Contact</PageTitle>
      <Container>
        <ContactText>
          Feel free to reach out if you have any questions or ideas you wish to
          share with me.
        </ContactText>
        <IconContainer>
          <StyledA href="mailto:hi@willemdeen.com" target="_blank">
            <IoMdMail />
          </StyledA>
          <StyledA href="https://twitter.com/willemisback" target="_blank">
            <FaTwitter />
          </StyledA>
          <StyledA href="https://github.com/d1che" target="_blank">
            <FaGithub />
          </StyledA>
        </IconContainer>
      </Container>
    </>
  );
};

ContactPage.Layout = Layout;

export default ContactPage;
